import logoWhite from './images/logo-white.svg';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
 } from '@material-ui/core';

function Landing() {
  return (
    <div className="landing">
      <section className="section-landing">
        <AppBar color="transparent" elevation={0} position="static">
          <Toolbar>
            <Box display="flex" flexGrow={1}>
              <img src={logoWhite} className="logo" alt="logo" />
            </Box>
            <Button variant="contained" 
            color="secondary"
            onClick={(e) => {
              window.location = 'mailto:leafcutterllc@gmail.com'
              e.preventDefault();
            }}>GET IN TOUCH</Button>
          </Toolbar>
        </AppBar>

        <div className="content">
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs="auto">
              <h3>Software development consulting for startups and established companies.</h3>
              <ul>
                <li>Ruby on Rails, Node.js</li>
                <li>Android, iOS, Flutter</li>
                <li>Google Cloud Platform, Amazon Web Services, Microsoft Azure, Heroku</li>
                <li>Alexa, Google Assistant, Siri integrations</li>
              </ul>
              <h4>Contact: leafcutterllc@gmail.com</h4>
            </Grid>
          </Grid>
        </div>

      </section>
    </div>
  );
}

export default Landing;
