import React from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme.json';
import Landing from './Landing';

const customTheme = createTheme(theme);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <Landing />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
